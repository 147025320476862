<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    attachModule="campoVisual"
    :hasExam="hasAttendanceValues('exam')"
    @getData="importAttendanceData(null, $event)"
  >
    <b-row>
      <b-col cols="12">
        <div class="form-group">
          <label for="campo-visual-aparelho">Aparelho utilizado</label>
          <multiselect
            :value="form.fields.aparelho"
            id="campo-visual-aparelho"
            :options="['Humphrey', 'Octupus']"
            :option-height="40"
            :showLabels="false"
            :searchable="false"
            :allowEmpty="false"
            placeholder="Selecionar"
            :disabled="!canEdit"
            class="with-border"
            @select="onSelect"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </div>
      </b-col>
      <b-col>
        <div class="custom-input-group" >
          <div class="custom-input">
            <div class="eye-area" >
              <EyeFill /> D
            </div>

            <div class="text-input">
              <div class="text-area type-2 with-br" :class="{ disabled: !canEdit }">
                <span>MD</span>
                  <TextArea
                    id="olhoDireitoMd"
                    rows="1"
                    :value="form.fields.olhoDireitoMd"
                    :readonly="!canEdit"
                    @input="value => updateForm('olhoDireitoMd', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control input"
                  />
              </div>
            </div>

            <div class="text-input ">
              <div class="text-area type-2 with-br" :class="{ disabled: !canEdit }">
                <span>PSD</span>
                  <TextArea
                    id="olhoDireitoPsd"
                    rows="1"
                    :value="form.fields.olhoDireitoPsd"
                    :readonly="!canEdit"
                    @input="value => updateForm('olhoDireitoPsd', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control input" 
                  />
              </div>
            </div>

            <div class="custom-input  laudo">
              <div class="text-area type-3 with-br" :class="{ disabled: !canEdit }">
                <span>Laudo</span>
                  <TextArea
                    id="laudoDireito"
                    rows="1"
                    :value="form.fields.laudoDireito"
                    :readonly="!canEdit"
                    @input="value => updateForm('laudoDireito', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                  />
              </div>
            </div>
          </div>

          <div class="custom-input">
            <div class="eye-area">
              <EyeFill /> E
            </div>
            <div class="text-input ">
              <div class="text-area type-2 with-br" :class="{ disabled: !canEdit }">
                  <TextArea
                    id="olhoEsquerdoMd"
                    rows="1"
                    :value="form.fields.olhoEsquerdoMd"
                    :readonly="!canEdit"
                    @input="value => updateForm('olhoEsquerdoMd', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control input"
                  />
              </div>
            </div>

            <div class="text-input ">
              <div class="text-area type-2 with-br" :class="{ disabled: !canEdit }">
                  <TextArea
                    id="olhoEsquerdoPsd"
                    :value="form.fields.olhoEsquerdoPsd"
                    :readonly="!canEdit"
                    rows="1"
                    @input="value => updateForm('olhoEsquerdoPsd', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control input"
                  />
              </div>
            </div>

            <div class="custom-input laudo">
              <div class="text-area type-3" :class="{ disabled: !canEdit }">
                  <TextArea
                    id="laudoEsquerdo"
                    rows="1"
                    :value="form.fields.laudoEsquerdo"
                    :readonly="!canEdit"
                    @input="value => updateForm('laudoEsquerdo', value)"
                    @blur="updateMedicalRecord(form)"
                    type="text"
                    class="form-control"
                  />
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <button
          class="btn blue-underline button-copy center"
          @click="copyEyeValues"
          v-if="canEdit"
          v-b-tooltip.hover title="Copiar valores O.D para O.E"
        >
          <Copy />
      </button>

      <b-col cols="12">
        <div class="custom-input ">
          <div class="text-area type-2 with-br">
          <span >Observação</span>
              <TextArea
                id="observacao"
                rows="1"
                :value="form.fields.observacao"
                :readonly="!canEdit"
                @input="value => updateForm('observacao', value)"
                @blur="updateMedicalRecord(form)"
                type="text"
                class="form-control"
              />
          </div>
        </div>
      </b-col>
    </b-row>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash'
import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'

export default {
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    TextArea: () => import('@/components/General/TextArea'),
    Copy: () => import('@/assets/icons/copy.svg'),
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.campoVisual,
      medicalRecordsStore: state => state.attendance.medicalRecordsStore
    }),
    ...mapGetters('attendance', ['exam'])
  },
  mixins: [attendanceDataMixin('medicalRecordsStore')],
  mounted() {
    this.debounceInput = debounce(this.onInput, 300)
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/campoVisual', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    onInput(key, value) {
      this.handleFields({ key, value })
      this.updateMedicalRecord(this.form)
    },
    openOptions(input){
      this.activeEyeBox === input ? this.activeEyeBox = null : this.activeEyeBox = input
    },
    setActiveEyeBox(BoxName){
      this.activeEyeBox = BoxName
    },
    onSelect(value) {
      this.updateForm('aparelho', value)
      this.updateMedicalRecord(this.form)
    },
    changeInputValue(change, amount, key, type){
      if(this.form.fields[key] === '' || this.form.fields[key] === null){
        this.form.fields[key] = '0'
      }
      if(!(isNaN(parseFloat(this.form.fields[key])))){
          if(type === 'eixo'){
              if(change === 'increase'){
                this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) + amount).toFixed(0)).replace('.', ','))

              } else if(change === 'decrease'){
                this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) - amount).toFixed(0)).replace('.', ','))
              }
              if(parseFloat(this.form.fields[key].replace(',', '.')) > 0){
                this.updateForm(key, this.form.fields[key]+'º')
              }
          }

          else if(type === 'DP'){
              if(change === 'increase'){
                this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) + amount).toFixed(1)).replace('.', ','))

              } else if(change === 'decrease'){
                this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) - amount).toFixed(1)).replace('.', ','))
              }
              if(parseFloat(this.form.fields[key].replace(',', '.')) > 0){
                this.updateForm(key, this.form.fields[key]+'')
              }
            }

          else if(!(isNaN(parseFloat(this.form.fields[key])))){
            if(change === 'increase'){
              this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) + amount).toFixed(2)).replace('.', ','))

            } else if(change === 'decrease'){
              this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) - amount).toFixed(2)).replace('.', ','))
            }
            if(parseFloat(this.form.fields[key].replace(',', '.')) > 0){
              this.updateForm(key, '+'+this.form.fields[key])
            }
        }
        this.updateMedicalRecord(this.form)
      }
    },
    copyEyeValues() {
      this.updateForm('olhoEsquerdoMd', this.form.fields.olhoDireitoMd)
      this.updateForm('olhoEsquerdoPsd', this.form.fields.olhoDireitoPsd)
      this.updateForm('laudoEsquerdo', this.form.fields.laudoDireito)
      setTimeout(() => { this.updateMedicalRecord(this.form) }, 500)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    },
    async importAttendanceData(medicalRecords = null, type) {
      if (!this.hasAttendanceValues(type)) return
      await this.simpleImportData(medicalRecords, type)
    },
  }
}
</script>
<style lang="scss" scoped>


.custom-input-group {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutral-300);
  border-radius: 8px !important;

  margin: 24px 0 16px 0;
  width: 100%;
  .custom-input {
    flex: 1;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--neutral-300);
    justify-content: center;

    .text-input {
      min-width: 20%;
      border-right: 1px solid var(--neutral-300);
      justify-content: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      display: flex;

      &.type-2 {

        .disabled {
          color: #8696ac;
          border: 1px solid #c6ceeb;
        }
        span {
          width: 100%;
          position: absolute;
          top: -19px;
          left: 0;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: var(--type-active);
        }
      }
      
    }
    .eye-area {
      width: 60px;
      background-color: var(--neutral-100);
      border-right: 1px solid var(--neutral-300);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px 0 0 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      svg {
        width: 16px;
        height: 16px;
        fill: var(--type-active);
        margin-right: 4px;
      }
    }

    &.no-bb {
      border-bottom: 0;

      .eye-area {
        border-radius: 0 0 0 8px;
      }
    }

    .text-area {
      flex: 1;
      display: flex;
      background-color: var(--neutral-100);
      justify-items: center;
      align-items: center;

      line-height: 55px;
      margin-left: 16px;
      font-weight: 400;
      font-size: 16px;
      color: var(--type-active);

      &.disabled {
        background-color: #e9ecef !important;
      }

      .form-control {
        border: 0 !important;

        &.input {
          justify-items: center;
          align-items: center;
          text-align: center;
        }
        
      }

      &.type-2 {
        width: 100%;
        position: relative;
        margin-left: 0;
        text-align: center;
        justify-items: center;
        background-color: var(--neutral-000);

        span {
          width: 100%;
          position: absolute;
          top: -19px;
          left: 0;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: var(--type-active);
        }
      }

      &.type-3 {
        width: 80%;
        position: relative;
        margin-left: 0;
        text-align: center;
        background-color: var(--neutral-000);
        
        span {
          width: 100%;
          position: absolute;
          top: -19px;
          left: 0;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: var(--type-active);
        }
      }

    }
  }
}
</style>
